import '../styles/globals.css'
import '../styles/bootstrap.css'
import env from '../src/config/environment'
import axios from 'axios'

axios.defaults.baseURL = env.apiUrl;

function MyApp({Component, pageProps}) {
    return <Component {...pageProps} />
}

export default MyApp
